import React from 'react'
import { styled } from 'utils/emotion'
import Icon from 'components/Icon'

const IconWrapper = styled('div')({
  textAlign: 'center',
  borderRadius: '50%',
  border: '3px solid #01b0ab',
  width: 100,
  height: 100,
  display: 'inline-block',
  padding: 18,
})

const Wrapper = styled('div')({
  width: '100%',
  position: 'fixed',
  background: '#110f0b',
  zIndex: 9999999999,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100vh',
  textAlign: 'center',
})

export default function PaymentFeedback() {
  return (
    <Wrapper>
      <IconWrapper>
        <Icon
          type="checkmark"
          width="65"
          height="65"
          fillPrimary="#02b0ab"
          currentColor={false}
        />
      </IconWrapper>
    </Wrapper>
  )
}
